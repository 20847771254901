@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/colors';

@keyframes spin-icon {
    0% {
        transform: rotateY(92deg);
    }

    80% {
        transform: rotateY(-40deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    padding: var(--block-padding-end) 0;
    background-color: var(--background-color);
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.lastBlock {
        padding-bottom: var(--last-block-padding-bottom);
    }
}

.inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 var(--block-padding-side);
}

.lockup {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.6rem;
}

.lockupInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading {
    margin-top: 1.2rem;
    margin-bottom: 2.4rem;
    font-size: 4.8rem;
    text-align: center;
    text-transform: uppercase;
}

.icon {
    width: 6rem;
    height: 6.6rem;
    background-image: url('/images/where-to-buy-cream.svg');
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotateY(90deg);

    .onEnter & {
        animation: spin-icon $superSlow $easeInOut 0.5s forwards;
    }

    .afterEnter & {
        transform: rotateY(0);
    }
}

.bodyCopy {
    max-width: 34.4rem;
    text-align: center;

    p {
        margin-bottom: 2rem;
    }
}

.track {
    position: relative;
    left: -25%;
    width: 100%;
    height: 24rem;
    margin: 0 auto;
    pointer-events: none;
}

.linkWrapper {
    z-index: 10;
}

.link {
    span {
        text-align: center;
    }
}

@include medium {
    .track {
        height: 30rem;
    }
}

@include large {
    .root {
        height: 60rem;
        min-height: 0;
        align-items: center;
    }

    .lockup {
        z-index: 1;
        align-items: flex-end;
        margin-bottom: 0;
    }

    .lockupInner {
        max-width: 42.6rem;
    }

    .headline {
        font-size: 6.4rem;
    }

    .bodyCopy {
        max-width: none;
    }

    .track {
        position: absolute;
        top: 50%;
        left: 0;
        height: 55%;

        @include center-y;
    }
}

@include xLarge {
    .heading {
        margin: 1.2rem 0 2.4rem;
    }
}
