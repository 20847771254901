@import 'styles/colors';
@import 'styles/mixins.scss';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

%laurels {
    display: block;
    content: '';
    flex-shrink: 0;

    width: 2.367rem;
    height: 5.362rem;

    background-image: url('/images/laurels.svg');
    background-repeat: no-repeat;

    @include medium {
        width: 3.338rem;
        height: 7.562rem;
    }
}

.root {
    display: flex;
    align-items: center;

    &:before {
        @extend %laurels;
        margin-right: 0.8rem;
    }
    &:after {
        @extend %laurels;
        margin-left: 0.8rem;
        transform: scaleX(-1);
    }

    p {
        margin-bottom: 0;
        font-family: $GTWalsheim;
        font-style: normal;
        font-weight: bold;
        font-size: 8.5px;
        line-height: 120%;

        text-align: center;
        letter-spacing: 1.41813px;
        text-transform: uppercase;
    }

    @include medium {
        p {
            font-size: 12px;
            letter-spacing: 2px;
        }
    }
}
