@import 'styles/breakpoints';
@import 'styles/mixins';

.leadership {
    --leadership-padding: 4.6rem 1rem;

    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: var(--leadership-padding);
    background-color: var(--colors-white);
    color: var(--colors-blue);
    text-align: center;

    @include rounded-corners;
}

.imageContainer {
    --image-dimension: 21rem;

    overflow: hidden;
    width: var(--image-dimension);
    height: var(--image-dimension);
    border-radius: 50%;
    pointer-events: none;
}

.image {
    height: 100%;
}

.name {
    --name-margin: 5.5rem 0 2rem;

    margin: var(--name-margin);
}

.position {
    --position-max-width: 24.1rem;

    max-width: var(--position-max-width);
    margin: 0 0 1rem;
    text-transform: uppercase;
}

@include xLarge {
    .leadership {
        --leadership-padding: 4.6rem 5.6rem;
    }

    .position {
        --position-max-width: 34.9rem;
    }

    .imageContainer {
        --image-dimension: 32rem;
    }
}
