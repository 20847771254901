@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

.slide {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 50%;
    height: 100%;
    align-items: flex-end;
    opacity: 1;
    transform: translateX(-100%);
    transition: transform 1s $easeInOutCubic;

    picture {
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;

        @include center-x;
    }

    &.enter {
        transition-duration: 1.2s;
    }
}

.slot-0 {
    transform: translate(-100%);
}

.slot-1 {
    transform: translate(0);

    &.idle {
        transform: translateX(-300%);
    }

    &.enter {
        transition-delay: 0.2s;
    }
}

.slot-2 {
    transform: translate(100%);

    &.idle {
        transform: translateX(-200%);
    }

    &.enter {
        transition-delay: 0.1s;
    }
}

.slot-3 {
    transform: translate(200%);

    &.idle {
        transform: translateX(-100%);
    }

    &.enter {
        transition-delay: 0s;
    }
}

.slot-4 {
    transform: translate(300%);
}

.no-transition {
    transition: none;
}

@include large {
    .slide {
        width: calc(100% / 6);
        opacity: 0.5;
        transition: transform 1s $easeInOutCubic, opacity 0.35s $easeInOutCubic;
    }

    .slot-1 {
        opacity: 1;
        transition: transform 1s $easeInOutCubic,
            opacity 1s $easeInOutCubic 0.1s;

        &.enter {
            opacity: 1;
        }
    }

    .slot-2 {
        opacity: 1;
    }

    .slot-3 {
        opacity: 1;
    }

    .slot-4 {
        opacity: 0;
    }

    .no-transition {
        transition: none;
    }
}

@include xLarge {
    .slide {
        width: calc(100% / 5);
    }
}
