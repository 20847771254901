@use 'sass:math';
@import 'styles/breakpoints';
@import 'styles/animations';

@keyframes shred {
    100% {
        transform: translateY(
                calc(var(--rootHeight) * 1px + calc(var(--height) * 1))
            )
            rotate(calc(var(--layer) * 260deg)) scale(var(--layerScaled));
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.root {
    position: absolute;
    z-index: 0;
    top: -2%;
    width: 100%;
    height: 120%;
    opacity: 0;
    pointer-events: none;

    --duration: calc(var(--rootHeight) / 100 * 1.5s);

    &.isLoaded {
        opacity: 1;
        transition: opacity $slow linear 0.2s;
    }
}

.shred {
    --layerScaled: 0.25;

    position: absolute;
    z-index: 5;
    z-index: calc(var(--z-index));
    top: -10%;
    animation: shred calc(calc(1 - var(--layer)) * var(--duration) + 7s)
            calc(calc(1 - var(--layer)) * var(--duration)) linear infinite both,
        fade-out calc(calc(1 - var(--layer)) * var(--duration) + 7s)
            calc(calc(1 - var(--layer)) * var(--duration)) linear infinite both;
    transform: translateY(calc(var(--height) * -2))
        rotate(calc(var(--layer) * 360deg)) scale(var(--layerScaled));

    &:nth-child(odd) {
        left: calc((var(--i) / var(--count)) * 100%);
    }

    &:nth-child(even) {
        right: calc((var(--i) / var(--count)) * 100%);
    }

    .sides & {
        &:nth-child(odd) {
            left: calc((var(--i) / var(--count)) * 20%);
        }

        &:nth-child(even) {
            right: calc((var(--i) / var(--count)) * 20%);
        }
    }
}

.shadows {
    position: relative;
    z-index: 6;
    width: 100%;
    height: 100%;
    transform: translateY(2rem);

    .shred {
        z-index: 5;
    }
}

@include large {
    .shred {
        --layerScaled: 0.33;
    }
}
