.root {
    position: relative;
    width: 100%;
    height: 100%;
}

// Animated Icon Active States
@mixin animated-icon-utensils-fork {
    :global(.animated-icon-utensils-fork) {
        rotate: -30deg;
        translate: -1.4rem 0;
    }
}

@mixin animated-icon-utensils-spoon {
    :global(.animated-icon-utensils-spoon) {
        rotate: 29deg;
        translate: 1.4rem 0;
    }
}

@mixin animated-icon-packaging-earth {
    :global(.animated-icon-packaging-earth) {
        rotate: -92deg;
    }
}

@mixin animated-icon-farmers-arrow {
    :global(.animated-icon-farmers-arrow) {
        rotate: -180deg;
    }
}
