@use 'sass:math';
@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    width: min(68vw, 30rem);
    height: auto;
    color: var(--colors-cream);
    text-align: center;
}

@include large {
    .title {
        width: clamp(33.6rem, 0rem + 32.813vw, 52.5rem);
    }
}
