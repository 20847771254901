@import 'styles/breakpoints';
@import 'styles/fonts';

@mixin fonts($size) {
    .#{$size}--displayXXLarge {
        font-family: $GTWalsheimUltraBold;
        font-size: 11rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--displayXLarge {
        font-family: $GTWalsheimUltraBold;
        font-size: 8rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--displayLargeFluid {
        font-family: $GTWalsheimUltraBold;
        font-size: 4.2vw;
        line-height: 1;
        font-weight: bold;
        @include xxxLarge {
            font-size: 8rem;
        }
    }
    .#{$size}--displayLarge {
        font-family: $GTWalsheimUltraBold;
        font-size: 6rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--displayMedium {
        font-family: $GTWalsheimUltraBold;
        font-size: 4.8rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--displaySmall {
        font-family: $GTWalsheimUltraBold;
        font-size: 3.6rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--displayXSmall {
        font-family: $GTWalsheimUltraBold;
        font-size: 3rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--displayXXSmall {
        font-family: $GTWalsheimUltraBold;
        font-size: 2rem;
        line-height: 1;
        font-weight: bold;
    }
    .#{$size}--headingXLarge {
        font-family: $GTWalsheim;
        font-size: 6rem;
        line-height: 1;
        font-weight: 500;
    }
    .#{$size}--headingLarge {
        font-family: $GTWalsheim;
        font-size: 3.6rem;
        line-height: 1.1;
        font-weight: 500;
    }
    .#{$size}--headingMedium {
        font-family: $GTWalsheim;
        font-size: 3rem;
        line-height: 1.2;
        font-weight: 500;
    }
    .#{$size}--headingMedium2 {
        font-family: $GTWalsheimUltraBold;
        font-size: 3rem;
        line-height: 1.2;
        font-weight: 800;
    }
    .#{$size}--headingSmall {
        font-family: $GTWalsheim;
        font-size: 2.4rem;
        line-height: 1.1;
        font-weight: 500;
    }
    .#{$size}--headingSmall2 {
        font-family: $GTWalsheimUltraBold;
        font-size: 2rem;
        line-height: 1.1;
        font-weight: 800;
    }
    .#{$size}--headingXSmall {
        font-family: $GTWalsheim;
        font-size: 1.8rem;
        line-height: 1.2;
        font-weight: 500;
    }
    .#{$size}--headingXSmall2 {
        font-family: $GTWalsheimUltraBold;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 800;
    }
    .#{$size}--bodyXLarge {
        font-family: $GTWalsheim;
        font-size: 3.2rem;
        line-height: 1.2;
        font-weight: 500;
    }
    .#{$size}--bodyLarge {
        font-family: $GTWalsheim;
        font-size: 2.4rem;
        line-height: 1.3;
        font-weight: 500;
    }
    .#{$size}--bodyMediumLarge {
        font-family: $GTWalsheim;
        font-size: 2rem;
        line-height: 1.35;
        font-weight: 500;
    }
    .#{$size}--bodyMedium {
        font-family: $GTWalsheim;
        font-size: 1.8rem;
        line-height: 1.3;
        font-weight: 500;
    }
    .#{$size}--bodySmall {
        font-family: $GTWalsheim;
        font-size: 1.6rem;
        line-height: 1.3;
        font-weight: 500;
    }
    .#{$size}--bodyXSmall {
        font-family: $GTWalsheim;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 500;
    }
    .#{$size}--labelLargeAlt {
        font-family: $GTWalsheim;
        font-size: 1.6rem;
        line-height: 1.2;
        letter-spacing: 0.4rem;
        font-weight: bold;
        text-transform: uppercase;
    }
    .#{$size}--labelLarge {
        font-family: $GTWalsheimCond;
        font-size: 1.6rem;
        line-height: 1.2;
        letter-spacing: 0.2rem;
        font-weight: bold;
    }
    .#{$size}--labelMediumAlt {
        font-family: $GTWalsheim;
        font-size: 1.4rem;
        line-height: 1.2;
        letter-spacing: 0.3rem;
        font-weight: bold;
        text-transform: uppercase;
    }
    .#{$size}--labelMedium {
        font-family: $GTWalsheimCond;
        font-size: 1.4rem;
        line-height: 1.2;
        letter-spacing: 0.15rem;
        font-weight: bold;
    }
    .#{$size}--labelSmall {
        font-family: $GTWalsheimCond;
        font-size: 1.4rem;
        line-height: 1.2;
        letter-spacing: 0.15rem;
        font-weight: bold;
    }
    .#{$size}--labelSmallAlt {
        font-family: $GTWalsheim;
        font-size: 1.2rem;
        line-height: 1.2;
        letter-spacing: 0.2rem;
        font-weight: bold;
    }
    .#{$size}--menuLarge {
        font-family: $GTWalsheim;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.3;
    }
    .#{$size}--menuSmall {
        font-family: $GTWalsheim;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 1.2;
    }

    // Parkinson themes (Maker's Reserve)

    .#{$size}--parkinsonXXLarge {
        font-family: $Parkinson;
        font-weight: 500;
        font-size: 10rem;
        line-height: 1;
    }
    .#{$size}--parkinsonXLarge {
        font-family: $Parkinson;
        font-weight: 500;
        font-size: 5rem;
        line-height: 1.2;
    }
    .#{$size}--parkinsonLarge {
        font-family: $Parkinson;
        font-weight: 500;
        font-size: 3.6rem;
        line-height: 1.2;
    }
    .#{$size}--parkinsonMedium {
        font-family: $Parkinson;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 1.3;
    }
    .#{$size}--parkinsonSmall {
        font-family: $Parkinson;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.3;
    }
    .#{$size}--parkinsonXSmall {
        font-family: $Parkinson;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.3;
    }

    // Search Results Page

    .#{$size}--searchResultsLarge {
        font-family: $GTWalsheimUltraBold;
        font-size: 3rem;
        line-height: 1;

        @include medium {
            font-size: 3.6rem;
        }
    }

    .#{$size}--searchResultsSmall {
        font-family: $GTWalsheim;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.2;

        @include medium {
            font-size: 2.4rem;
        }
    }
}

@include fonts('base');
@include medium {
    @include fonts('medium');
}
@include large {
    @include fonts('large');
}
@include xLarge {
    @include fonts('xLarge');
}
@include xxLarge {
    @include fonts('xxLarge');
}
@include xxxLarge {
    @include fonts('xxxLarge');
}
