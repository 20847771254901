@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    --scrollbar-border: 0.35rem;
    --scrollbar-color: #b8b8b8;
    --nav-prev-left: 1rem;
    --nav-next-right: 1rem;

    width: 100%;
    opacity: 0;
    transform: translateX(30rem);
    transition-duration: $superSlow;
    transition-property: transform, opacity;
    transition-timing-function: $easeOutCubic;

    &.isInView {
        opacity: 1;
        transform: translateX(0);
    }

    @media (hover: hover) {
        &:hover {
            .navigation {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.container {
    margin-top: -4.8rem; // offset for card hover
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    @media (hover: hover) {
        &::-webkit-scrollbar {
            width: 1.5rem;
        }

        &::-webkit-scrollbar-thumb {
            border: var(--scrollbar-border) solid transparent;
            border-radius: 2rem;
            background-clip: content-box;
            background-color: transparent;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-color);
        }
    }

    .hideScrollBars & {
        @media (hover: hover) {
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.track {
    display: flex;
    width: 100%;
    min-width: min-content;
    justify-content: var(--track-justify-content, flex-start);
    padding: 4.8rem 0 0; // offset for card hover
    margin: 0;
    gap: var(--slide-spacing, 1rem);
    list-style: none;
}

.slide {
    position: relative;
    display: block;
    scroll-snap-align: center;

    .inlineIntro & {
        &::before,
        &::after {
            position: absolute;
            top: 0;
            right: -1.2rem;
            width: 3rem;
            height: 100%;
            background-color: var(--slide-gap-color);
            content: '';
        }

        &::after {
            z-index: -1;
            left: -1.2rem;
        }

        &:first-child,
        &:last-child {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        &:nth-child(2) {
            &::after {
                display: none;
            }
        }
    }
}

.navigation {
    opacity: 0;
    pointer-events: none;
    transition: opacity $fast $easeOut;
}

.button {
    position: absolute;
    z-index: var(--z-nav);
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 10%);

    @include center-y;

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 17px 20px rgba(0, 0, 0, 20%);
        }
    }

    &:disabled {
        opacity: 0;
    }

    .blue & {
        background-color: var(--colors-blue);
        color: var(--colors-cream);

        @media (hover: hover) {
            &:hover {
                background-color: var(--colors-blueOcean);
            }
        }
    }

    .cream & {
        background-color: var(--colors-cream);
        color: var(--colors-blue);

        @media (hover: hover) {
            &:hover {
                background-color: var(--colors-white);
            }
        }
    }

    .orange & {
        @include ncdTextureOrangeMedium;
        @include ncd-circle-drop-shadow;

        @media (hover: hover) {
            &:hover {
                @include ncdTextureOrangeDark;
            }
        }
    }
}

.prev {
    left: var(--nav-prev-left);
}

.next {
    right: var(--nav-next-right);
}

.icon {
    width: 1rem;
}

@include medium {
    .slide {
        .inlineIntro & {
            pointer-events: auto;
        }

        .isBeginning.inlineIntro & {
            &:first-child {
                pointer-events: none; // allow InlineIntro to be clicked
            }
        }
    }
}
