@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/colors';

.root {
    --timing: 1s;

    position: fixed;
    top: calc(var(--header-height) + var(--header-offset));
    width: 100%;

    @include center-x;
    @include ncdTextureOrangeMedium;
}

.inner,
.isLoaded,
.isIntro {
    position: relative;
    width: 100%;
    height: 100%;
}

.header {
    --easing-header-in: cubic-bezier(0.26, 1.8, 0.02, 1);
    --easing-header-out: cubic-bezier(0.35, 1.4, 0.02, 1);

    position: relative;
    z-index: var(--z-ncd-header);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);
    gap: 0.75rem;
    opacity: 0;
    scale: 1;
    translate: 0 60% 0;

    .isIntro & {
        color: var(--colors-blue);
        opacity: 1;
        scale: 1.25;
        transition: opacity $default linear,
            translate var(--timing) var(--easing-header-in) 0.05s,
            scale var(--timing) var(--easing-header-in) 0.05s,
            color var(--timing) var(--easing-header-in) 0.05s;
        translate: 0 50% 0;
    }

    .isLoaded & {
        opacity: 1;
        scale: 1;
        transition: opacity $default linear, scale 1.3s var(--easing-header-out),
            translate 1.3s var(--easing-header-out),
            color 1.3s var(--easing-header-out);
        translate: 0 0 0;
    }
}

.headline {
    position: relative;
    font-size: 2.4rem;
    text-transform: uppercase;
}

@include medium {
    .header {
        gap: 1.5rem;
        scale: 1.2;
        translate: 0 40% 0;

        .isIntro & {
            scale: 1.25;
            translate: 0 36% 0;
        }
    }

    .headline {
        font-size: clamp(3.5rem, -0.1444rem + 3.559vw, 5.55rem);
    }
}
