@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    display: flex;
    max-width: var(--block-max-width-plus-padding);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--block-padding-end) 2.4rem 0;
    margin: 0 auto;
    color: var(--colors-blue);
    gap: 2.4rem;
}

.title {
    text-transform: uppercase;
}

.searchContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 0.8rem;
}

.filterButton {
    width: 5rem;
    height: 5rem;
    flex-shrink: 0;
    border: none;
    border-radius: 0.8rem;
    background-color: var(--colors-blue);
    transition: background-color $default $easeOutQuint;

    @media (hover: hover) {
        &:hover {
            background-color: var(--colors-blueOcean);
        }
    }

    &.isOpen {
        background-color: var(--colors-blueOcean);
        transition: background-color $default $easeOutQuint;
    }

    svg {
        width: 3rem;
        height: 3rem;
    }
}

@include smallOnly {
    .title {
        font-size: 2.4rem;
    }
}

@include medium {
    .root {
        flex-direction: row;
        align-items: center;
        padding: var(--block-padding-end) var(--block-padding-side) 0;
    }

    .searchContainer {
        justify-content: flex-end;
    }
}
