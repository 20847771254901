@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    padding: 1rem;
    color: var(--colors-blue);
}

.text {
    position: relative;
    padding: 9rem 1rem;
    font-size: 2.4rem;
    text-align: center;

    q {
        hyphens: auto;
        word-wrap: break-word;
    }
}
