@import 'styles/breakpoints';

.commitmentSubnav {
    position: relative;
}

.aspect {
    position: relative;
    width: 100%;
    height: var(--aspect-height, calc(100vh - 10rem));
    min-height: var(--aspect-min-height, 40rem);

    @media (height >= 620px) {
        --aspect-height: calc(100vh - 16rem);
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.richHeadline {
    span {
        display: block;
    }
}

.contentWrapper {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--block-padding-end);
    color: var(--colors-cream);
    text-align: center;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
}

.pageIdx {
    margin-bottom: var(--idx-margin-bottom, 2rem);
}

.calloutWrapper {
    padding: 0 var(--block-padding-side);
    margin: var(--calloutWrapper-margin, -4.8rem 0 0 0);
}

.navArrow {
    display: none;
}

.ctaWrapper {
    position: absolute;
    bottom: 11rem;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: var(--ctaWrapper-transform, translate(-50%, 0));
}

@include medium {
    .aspect {
        --aspect-height: calc(100vh - 6rem);
        --aspect-min-height: 60rem;
    }

    .ctaWrapper {
        --ctaWrapper-transform: translate(-50%, -5rem);

        bottom: 16%;
    }

    .calloutWrapper {
        --calloutWrapper-margin: -7.4rem 0 0 0;
    }
}

@include large {
    .pageIdx {
        --idx-margin-bottom: 3rem;
    }

    .navArrow {
        position: absolute;
        top: 50%;
        display: block;
        transform: translateY(-50%) translateY(-4rem);

        &.left {
            left: var(--block-padding-end);
        }

        &.right {
            right: var(--block-padding-end);
        }
    }

    .aspect {
        --aspect-height: calc(100vh - 8rem);
    }

    .calloutWrapper {
        --calloutWrapper-margin: -9.6rem 0 0 0;
    }
}

@include xxLarge {
    .navArrow {
        &.left {
            left: var(--block-padding-side);
        }

        &.right {
            right: var(--block-padding-side);
        }
    }

    .ctaWrapper {
        bottom: 13rem;
    }
}
