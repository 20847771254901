@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    width: max-content;
    height: 4rem;
    align-items: center;
    justify-content: center;
    padding: 0 1.4rem 0 2.2rem;
    border: none;
    border: 0.1rem solid transparent;
    border-radius: 5rem;
    background-color: var(--colors-blue);
    color: var(--colors-cream);
    cursor: pointer;
    grid-row: span 1;
    text-align: center;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    svg {
        width: 2.8rem;
        height: 2.8rem;
    }

    @media (hover: hover) {
        &:hover {
            border: 0.1rem solid rgba(color('blue'), 0.15);
            transition: border-color $fast $easeOut;
        }
    }
}

.isToggle {
    padding: 0 1.2rem 0 1.6rem;
    margin: 0 0.3rem;
    background-color: var(--colors-cream);
    color: var(--colors-blue);
    transition: background-color $default $easeOut, color $default $easeOut,
        opacity $default $easeOut;

    &.isDisabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &.isActive {
        background-color: var(--colors-blue);
        color: var(--colors-white);
    }
}

.closeIcon {
    display: flex;
    align-items: center;
    padding-left: 0.8rem;

    svg {
        width: 0.8rem;
        height: 0.8rem;
    }
}

.label {
    width: max-content;
    margin: 0 auto;
    text-transform: uppercase;
}

.checkIcon {
    svg {
        width: 1.6rem;
        height: 1.2rem;
    }

    padding-right: 1.1rem;
}

@include medium {
    .isToggle {
        padding: 0 2rem;
        border: 0.1rem solid transparent;
        border-radius: 5rem;
        grid-row: span 1;
        transition: background-color $default $easeOut, color $default $easeOut,
            opacity $default $easeOut, border-color $fast $easeOut;
    }
}

@include large {
    .root {
        height: 4.4rem;
    }

    .isToggle {
        margin: 0;
    }
}
