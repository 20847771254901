@import 'styles/breakpoints';

.cardSmall {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--card-padding, 0 0 117% 0);
    height: 0;
    background-color: var(--colors-white);
}

.cardLinkWrap {
    width: 100%;
}

.inner {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: var(--inner-padding, 4.8rem);
}

.icon {
    position: relative;

    .largeIcon & {
        width: 20rem;
        height: 20rem;
    }
}

.copy {
    margin: 2rem 0 0;

    p {
        margin: 0;
    }
}

@include medium {
    .copy {
        margin: 0rem 0 2rem 0;
    }
}

@include large {
    .cardLatest {
        --inner-padding: 6.2rem;

        --headline-max-width: 34.9rem;

        --card-padding: 0 0 131.12% 0;
    }

    .copy {
        margin: 1rem 0 0;
    }
}
