@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    background-color: var(--colors-white);
    color: var(--colors-blue);
    flex: var(--listItem-flex);
    height: 100%;

    @include rounded-corners($overflow: hidden);

    &:last-child {
        margin-right: 0;
    }
}

.link {
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.textBlock {
    height: 100%;
    width: 100%;
    min-height: var(--textBlock-min-height, 13rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--textBlock-padding, 2.4rem);

    &.date {
        justify-content: flex-start;
    }

    @media (hover: hover) {
        &:hover {
            .image {
                transform: scale(1.02);
            }
        }
    }
}

.storySubHeadline {
    margin: 0;
    text-transform: uppercase;
}

.storyDate {
    width: 100%;
}

.storyHeadline {
    margin: var(--storyHeadline-margin, 1rem 0);
}

.aspectBox {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-color: var(--colors-black);
    overflow: hidden;
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $slow $easeOutCubic;
}

@include medium {
    .aspectBox {
        padding-top: 75%;
    }

    .storyHeadlineWrapper {
        width: 100%;
    }

    .storyHeadline {
        width: 100%;

        --storyHeadline-margin: 1.6rem 0;
    }

    .listItem {
        --listItem-margin: 0 4.8rem 0 0;
        --listItem-flex: 1 50%;
    }
}

@include xxLarge {
    .aspectBox {
        padding-top: 100%;
    }

    .textBlock {
        --textBlock-padding: 4.8rem;
        --textBlock-min-height: 20rem;
    }
}
