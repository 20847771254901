@import 'styles/breakpoints';
@import 'styles/mixins';

.listItem {
    height: 100%;
    background-color: var(--colors-white);
    color: var(--colors-blue);

    @include rounded-corners($overflow: hidden);
}

.inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.aspectBox {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 100%;
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.textBlock {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    text-align: center;
}

.date {
    margin-bottom: 1.2rem;
}

@include medium {
    .textBlock {
        padding: 4.8rem;
    }

    .listItem {
        --listItem-margin: 0 4.8rem 0 0;
        --listItem-flex: 1 50%;
    }
}
