@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    background-color: var(--colors-white);
}

.inner {
    position: relative;
    display: flex;
    max-width: var(--block-max-width-plus-padding);
    flex-direction: column;
    padding: 4rem 2.4rem;
    margin: 0 auto;
    gap: 2rem;
}

.category {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column wrap;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(color('blue'), 0.15);
    gap: 1.2rem;

    &:last-of-type {
        border-bottom: none;
        margin-right: 0;
    }
}

.items {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 42rem;
    flex-flow: row wrap;
    flex-shrink: 0;
    margin-right: 4.8rem;
    gap: 1rem;

    button {
        grid-column: span 2;
    }
}

.title {
    font-weight: 500;
}

@include medium {
    .items {
        max-width: 54rem;
    }

    .category {
        gap: 2.4rem;
    }

    .inner {
        padding: 4rem var(--block-padding-side);
    }
}

@include large {
    .root {
        gap: 2.4rem;
    }

    .items {
        padding-right: 4.8rem;
        gap: 1rem;
    }
}

@include xLarge {
    .items {
        --gridColumnAreas: 'a a a a a a a a';

        display: grid;
        grid-auto-columns: 1fr;
        grid-gap: 0.8rem;
        grid-template-areas: var(--gridColumnAreas);
    }
}

@include xxLarge {
    .root {
        gap: 4.8rem;
    }

    .inner {
        flex-direction: row;
        gap: 4.8rem;
    }

    .category {
        border-right: 1px solid rgba(color('blue'), 0.15);
        border-bottom: none;

        &:last-of-type {
            border-right: none;
            margin-right: 0;
        }
    }

    .items {
        display: flex;
        width: calc(18rem * var(--column-size));
        height: 41.5rem;
        flex-flow: column wrap;
        flex-shrink: 0;
        margin-right: 4.8rem;
        gap: 1rem;
    }
}
