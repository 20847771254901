@import 'styles/breakpoints';
@import 'styles/animations';

.commitmentNavContainer {
    position: fixed;
    z-index: var(--z-subnav);
    top: var(--nav-top, 6rem);
    left: 0;
    width: 100%;
    transition: transform $fast $easeInOutCubic;

    &.isHidden {
        transform: translateY(-14rem);
    }
}

.commitmentNav {
    position: relative;
    width: 100%;
    height: var(--nav-height, 5.4rem);
    background-color: var(--colors-white);
    color: var(--colors-blue);
    transition: transform $default $easeInOutCubic;

    &.isExpanded {
        transform: translateY(-6rem);
    }
}

.currentPage {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;

    &.shrink {
        opacity: 0;
        pointer-events: none;
        transition: opacity $default $easeOutCubic, visibility $default linear,
            transform $default $easeOutCubic;
        visibility: hidden;
    }
}

.currentPageSeparator {
    width: 0.9rem;
    height: 0.15rem;
    margin: 0 0.4rem;
    background-color: var(--colors-blue);
}

.buttonCircle {
    margin-left: 2rem;
}

.nav {
    position: absolute;
    top: 5.4rem;
    left: 0;
    width: 100%;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    transition: transform $default $easeOutCubic, visibility $default linear,
        opacity $default $easeOutCubic;
    visibility: hidden;

    &.isExpanded {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        visibility: visible;
    }
}

.pageList {
    display: flex;
    flex-direction: var(--page-list-flex-direction, column);
    padding: var(--page-list-padding, 2rem 0);
    margin: 0;
    list-style: none;
}

.page {
    display: flex;
    flex-direction: var(--page-flex-direction, row);
    align-items: center;
    padding: var(--page-padding, 2rem 1.7rem 2rem 2.5rem);
    cursor: pointer;
}

.pageLabel {
    display: flex;
    flex-direction: column;
}

.pageArrow {
    margin-left: auto;
}

// Width = widest icon
.iconBox {
    display: flex;
    width: var(--icon-box-width, 6.5rem);
    justify-content: center;
    margin: var(--icon-box-margin, 0 2rem 0 0);
}

.commitmentIconWrapper {
    position: relative;
    width: 4.8rem;
    height: 4.8rem;

    &.icon--barn {
        width: 6.5rem;
        height: 3.2rem;
    }

    &.icon--hills {
        width: 6.4rem;
        height: 4rem;
    }

    &.icon--people {
        width: 5.7rem;
        height: 4.5rem;
    }
}

@include large {
    .commitmentNavContainer {
        --nav-top: 8rem;
    }

    .commitmentNav {
        --nav-height: 6rem;
    }

    .pageList {
        --page-list-flex-direction: row;
        --page-list-padding: 3.1rem 12.8rem;

        display: flex;
        width: 100%;
        max-width: 144rem;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
    }

    .page {
        --page-flex-direction: column;
        --page-padding: 0;

        text-align: center;
    }

    // Force line break
    .pageLabel {
        max-width: 11rem;
    }

    .pageArrow {
        display: none;
    }

    .indexIndicator {
        display: none;
    }

    // height = tallest icon
    .iconBox {
        --icon-box-width: auto;

        height: 4.8rem;

        --icon-box-margin: 0 0 0.4rem 0;

        align-items: center;
    }
}
