@use '~@tillamook/brand/lib/colors' as brand-colors;

/* Define site colors */
/* stylelint-disable */
$colors: (
    'rust': #c04d3e,
    'purple': #ff00ff,
    'categoryIceCreamBrown': #a0502f,
    'categoryCheeseBabyLoaves': #272727,
    'categoryCreamCheeseLighter': #badaf3,
    'cheddarCheeseCurdsSpecial': #ffb119,
    'cheeseCheddarMediumSpecialGradient': #ff9d19,
    'cheeseCheddarMediumSpecial': #ec7f1a,
    'cheeseCheddarSharpSpecial': #b40f0f,
    'cheeseExtraSharpCheddarSpecial': #282c2f,
    'makersReserveSpecial': #121212,
    'makersReserveOffBlack': #191815,
    'makersReserveBlack': #191815,
    'makersReserveGold': #8f764d,
    'makersReserveGoldAlt': #9f7c45,
    'creamCheeseSeriouslyStrawberry': #f5887f,
    'strawberry': #ffcccd,
    'strawberryAccent': #e28182,
    'oldFashionedVanilla': #f8e7b4,
    'oldFashionedVanillaAccent': #ffd980,
    'creameryQRStrawberry': #e77f80,
    'creameryQRBackground': #fff3d7,
    'blueOcean': #41b6e6,
    'transparent': transparent,
    'coreBrandCream': #fcfae6,
    'creamHeavy': #ffefc6,
);

@function color($color) {
    @if map-has-key($colors, $color) {
        @return map-get($colors, $color);
    } @else {
        @return map-get(brand-colors.$colors, $color);
    }
}

@mixin ncdTextureCreamLight {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #fffce6;
    background-blend-mode: overlay, normal;

    @include touchscreens {
        background-size: 50%;
    }
}

@mixin ncdTextureCreamDark {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #fffce6;
    background-blend-mode: overlay, multiply, normal;

    @include touchscreens {
        background-size: 50%;
    }
}

@mixin ncdTextureOrangeLight {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #fccb8c;
    background-blend-mode: overlay, normal;

    @include touchscreens {
        background-size: 50%;
    }
}

@mixin ncdTextureOrangeMedium {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #ffa439;
    background-blend-mode: overlay, multiply, normal;

    @include touchscreens {
        background-size: 50%;
    }
}

@mixin ncdTextureOrangeDark {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #ff7d04;
    background-blend-mode: overlay, multiply, normal;

    @include touchscreens {
        background-size: 50%;
    }
}

@mixin ncdTextureBlueLight {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #84d5f7;
    background-blend-mode: overlay, multiply, normal;

    @include touchscreens {
        background-size: 50%;
    }
}

@mixin ncdTextureBlueMedium {
    background: url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        url('//images.ctfassets.net/j8tkpy1gjhi5/248tlaC4uksBDblV89Rdj1/ba02a661c883d6f0271370ef08dac77b/bg-texture.png?fm=webp&q=80'),
        #71c5ea;
    background-blend-mode: overlay, multiply, normal;

    @include touchscreens {
        background-size: 50%;
    }
}
