@import 'styles/animations';

.root {
    position: relative;
    width: 100%;
    height: 100%;

    :global(.animated-icon-farmers-arrow) {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1.2rem;
        transform: translate(-50%, -50%);
        transform-origin: 0 0;
        transition: rotate $slow $bounce;
    }
}
