@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    color: var(--colors-blue);
    height: 100%;

    @media (hover: hover) {
        &:hover {
            .image {
                transform: scale(1.02);
            }
        }
    }
}

.inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.aspectBox {
    position: relative;
    width: 100%;
    padding-top: 100%;
    height: 0;
    overflow: hidden;

    @include rounded-corners($overflow: hidden);
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $slow $easeOutCubic;
}

.infoWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    transform: translateY(-2rem);
}

.infoLockup {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 1rem;
}

.timeIcon {
    display: inline-block;
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    margin: -0.1rem 0.8rem 0 0;
}

.timeLabel {
    font-weight: bold;
}

.separator {
    display: inline-block;
    transform: translateY(-0.1rem);
    margin: 0 0.9rem 0 1rem;
}

.category {
    text-transform: uppercase;
    background-color: var(--colors-white);
    padding: 1.35rem 2rem;
    margin-bottom: 2.2rem;
    width: fit-content;

    @include rounded-corners;
}

.headlineWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.creator {
    margin: 2rem 0 0;
}

@include large {
    .aspectBox {
        .doubleWide & {
            // account for grid gap so double-wide images aren't taller than single-wide images
            padding-top: calc(50% - 0.6rem);
        }
    }

    .infoLockup {
        margin-bottom: 3rem;
    }
}
