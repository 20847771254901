@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/animations';

@mixin card-hover {
    > button:nth-of-type(odd) {
        rotate: -6deg;

        .info {
            pointer-events: none;
            rotate: 6deg;
            translate: -6%;
        }

        @media (hover: hover) {
            &:hover {
                rotate: -3deg;

                .info {
                    pointer-events: none;
                    rotate: 3deg;
                }
            }
        }
    }

    > button:nth-of-type(even) {
        rotate: 6deg;
        translate: 6%;

        .info {
            pointer-events: none;
            rotate: -6deg;
        }

        @media (hover: hover) {
            &:hover {
                rotate: 3deg;

                .info {
                    pointer-events: none;
                    rotate: -3deg;
                }
            }
        }
    }
}

.root {
    --timing: 1s;
    --easing-enter: cubic-bezier(0.26, 1.2, 0.2, 1);

    position: relative;
    z-index: 50;
    width: 100%;
    margin-top: calc(
        var(--header-height) + var(--header-offset) + var(--hero-height, 0)
    );
    opacity: 0;
    transform: scale(var(--scale-progress));
    transform-origin: top;
    translate: 0 100% 0;

    &.isLoaded {
        opacity: 1;
        transition: translate var(--timing) var(--easing-enter),
            opacity calc(var(--timing) / 2) linear;
        translate: 0 0 0;
    }
}

.inner {
    --container-border-radius: 3rem;

    position: relative;
    z-index: 200;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: var(--block-padding-end) 0 0;
    border-radius: calc(
            var(--container-border-radius) * var(--border-radius-progress)
        )
        calc(var(--container-border-radius) * var(--border-radius-progress)) 0 0;
    background-color: var(--colors-cream);
    color: var(--colors-blue);
    gap: 6rem;

    .cta {
        span {
            border-radius: 10rem;
        }

        @include block-jams-cta;
    }
}

.cards {
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: -10rem;
    transform: translateZ(0);
    transition: margin 0.5s $easeInOut;
    will-change: margin;

    &.isActive {
        margin-bottom: 20rem;
        transform: translateZ(0);
        transition: margin 0.5s $easeInOut;
        will-change: margin;
    }

    @include card-hover;

    > button {
        animation-direction: alternate;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: ambient;
        animation-play-state: running;
        animation-timing-function: $easeInOutQuad;
        transition: rotate $default $easeInOutQuad,
            translate 0.7s $easeInOutQuad, scale 0.7s $easeInOutQuad;

        .info {
            transition: visibility 0.5s 0.5s, opacity 0.5s,
                rotate $default $easeInOutQuad;
        }
    }

    @for $i from 1 through 4 {
        > button:nth-of-type(#{$i}) {
            animation-delay: calc(#{$i * 0.1}s);
        }
    }
}

.info {
    position: absolute;
    right: 0;
    bottom: -100%;
    left: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    pointer-events: none;
    text-align: center;
    transform-origin: top;

    .infoCta {
        pointer-events: all;

        span {
            border-radius: 10rem;
        }

        @include block-jams-cta;
    }
}

.infoBody {
    width: 36.6rem;
    max-width: 30rem;
    font-size: clamp(1.68rem, 0.0145rem + 2.1687vw, 2.4rem);
}

.infoBody,
.infoCta {
    opacity: 0;
    transition: opacity 0.5s;
}

.active-1 {
    > button:nth-of-type(1) {
        animation-play-state: paused;
        scale: 1.1;

        .infoBody,
        .infoCta {
            opacity: 1;
            transition: opacity 0.5s linear 0.5s;
        }
    }

    > button:nth-of-type(2),
    > button:nth-of-type(3),
    > button:nth-of-type(4) {
        translate: 0 100%;
    }
}

.active-2 {
    > button:nth-of-type(2) {
        animation-play-state: paused;
        scale: 1.1;

        .infoBody,
        .infoCta {
            opacity: 1;
            transition: opacity 0.5s linear 0.5s;
        }
    }

    > button:nth-of-type(3),
    > button:nth-of-type(4) {
        translate: 0 120%;
    }
}

.active-3 {
    > button:nth-of-type(3) {
        animation-play-state: paused;
        scale: 1.1;

        .infoBody,
        .infoCta {
            opacity: 1;
            transition: opacity 0.5s linear 0.5s;
        }
    }

    > button:nth-of-type(4) {
        translate: 0 120%;
    }
}

.active-4 {
    > button:nth-of-type(4) {
        animation-play-state: paused;
        scale: 1.1;

        .infoBody,
        .infoCta {
            opacity: 1;
            transition: opacity 0.5s linear 0.5s;
        }
    }
}

.gradient {
    position: relative;
    z-index: 100;
    top: 0;
    display: flex;
    width: 100%;
    height: 12rem;
    background: linear-gradient(to bottom, #fffce6, rgba(255, 0, 0, 0%));
}

.header {
    position: relative;
    display: flex;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
}

.cta {
    margin-bottom: 2rem;
}

.headline {
    font-size: 2.8rem;
    text-transform: uppercase;

    strong {
        font-size: 5.6rem;
        line-height: 0.85;
        text-align: center;

        @include block-text(var(--colors-cream));
    }
}

.body {
    max-width: 32rem;
    margin: 2.4rem 0;
    text-align: center;
}

@include medium {
    .cards {
        flex-direction: row;

        &.isActive {
            margin-bottom: 0;
            transform: translateZ(0);
            transition: margin 0.5s $easeInOut;
            will-change: margin;
        }

        > button:nth-of-type(even) {
            translate: 0 4rem;
        }
    }

    .inner {
        --container-border-radius: 6rem;

        gap: 10rem;
    }

    .cta {
        margin-bottom: 0;
    }

    .body {
        max-width: 68.4rem;
        margin-top: 2.4rem;
        margin-bottom: 4.8rem;
    }

    .headline {
        font-size: 6rem;
    }

    .info {
        right: unset;
        left: 50%;
        transform-origin: unset;

        @include center-y;

        .infoBody,
        .infoCta {
            opacity: 1;
        }
    }

    .active-1 {
        > button:nth-of-type(1) {
            scale: 1.1;
            translate: 75% 0;
        }

        > button:nth-of-type(2),
        > button:nth-of-type(3),
        > button:nth-of-type(4) {
            translate: 275% 0;
        }
    }

    .active-2 {
        > button:nth-of-type(1) {
            translate: -75% 0;
        }

        > button:nth-of-type(2) {
            scale: 1.1;
            translate: -25% 0;
        }

        > button:nth-of-type(3),
        > button:nth-of-type(4) {
            translate: 175% 0;
        }
    }

    .active-3 {
        > button:nth-of-type(1),
        > button:nth-of-type(2) {
            translate: -175% 0;
        }

        > button:nth-of-type(3) {
            scale: 1.1;
            translate: -125% 0;
        }

        > button:nth-of-type(4) {
            translate: 75% 0;
        }
    }

    .active-4 {
        > button:nth-of-type(1),
        > button:nth-of-type(2),
        > button:nth-of-type(3) {
            translate: -250% 0;
        }

        > button:nth-of-type(4) {
            scale: 1.1;
            translate: -225% 0;
        }
    }
}

@include large {
    .headline {
        strong {
            font-size: 12rem;
        }
    }

    .infoBody {
        max-width: 36.5rem;
    }
}

@keyframes ambient {
    0% {
        transform: translateY(-0.5rem);
    }

    100% {
        transform: translateY(0.5rem);
    }
}
