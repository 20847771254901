@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

@mixin card-hover {
    @media (hover: hover) {
        &:hover {
            .back {
                background-image: url('/images/campaigns/block-jams-24/card-reveal-bg-stars.png');
                background-position-x: center;
                background-position-y: 26%;
                background-repeat: no-repeat;
                background-size: 81%;
            }
        }
    }
}

.wrapper {
    position: relative;
    width: 100%;
    max-width: 20rem;
    height: 100%;
    padding: 0;
    border: none;
    appearance: none; // fix safari overflow border-radius bug
    background-color: transparent;
    perspective: 600px;
    transform: translateZ(0); // fix safari overflow border-radius bug
}

.root {
    position: relative;
    width: 100%;
    max-width: 20rem;
    height: 100%;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 1.6rem;
    background-color: var(--colors-cream);
    box-shadow: -4px 4px 0 0 rgba(0, 30, 96, 100%);
    color: var(--colors-blue);
    transform: translateZ(0); // fix safari overflow border-radius bug
    transform-origin: bottom;
    transition: transform $slow $easeOutCubic;

    @include card-hover;
}

.inner {
    --padding: 1rem;

    position: relative;
    flex-direction: column;
    justify-content: center;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 0.8rem;
    margin: var(--padding);
    aspect-ratio: 0.791;
    background: #ffb548;
    transform: translateZ(0); // fix safari overflow border-radius bug
    transition: transform $slow $easeOutCubic;

    @include flex-center;

    .isFront & {
        background: #f18a00;
    }

    &.isCreamCheese {
        @include ncdTextureBlueLight;
    }
}

.imageContainer {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.title {
    position: relative;
    max-width: 22rem;
    text-align: center;
    text-transform: uppercase;

    strong {
        @include block-text(var(--colors-cream));
    }
}

.label {
    position: absolute;
    width: 100%;
    text-transform: uppercase;

    @include center-x;
}

.eyebrow {
    padding: 1.4rem 0 0.8rem;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.back {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    transform: rotateY(180deg);

    .title {
        font-size: 2.2rem;

        strong {
            font-size: 3.6rem;
        }
    }

    .label {
        bottom: 2.4rem;
        backface-visibility: hidden;
    }

    .isFront & {
        backface-visibility: visible;

        .label {
            backface-visibility: visible;
        }
    }
}

.isFront {
    transform: rotateY(180deg);
}

.front {
    position: absolute;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    .isFront & {
        backface-visibility: hidden;
        transform: rotateY(180deg);
    }

    .title {
        position: relative;
        max-width: 22rem;
        font-size: 1.8rem;
        text-align: center;
        text-transform: uppercase;

        strong {
            font-size: 3.2rem;

            @include block-text(var(--colors-cream));
        }
    }

    .label {
        position: absolute;
        bottom: 2.4rem;
        text-transform: uppercase;

        @include center-x;
    }
}

.textContainer {
    display: flex;
    max-width: 20rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.image {
    position: relative;
    z-index: 10;
    width: 100%;
    object-fit: contain;
}

.body {
    width: 36.6rem;
}

@include smallOnly {
    .image {
        max-height: 11rem;
        margin: 0 auto;
    }
}

@include medium {
    .wrapper {
        max-width: 30rem;
    }

    .root {
        max-width: 30rem;
        transform-origin: center;

        > div {
            display: none;
        }

        &.isActive {
            > div {
                display: none;
            }
        }
    }

    .front {
        .title {
            font-size: clamp(1.675rem, -0.0021rem + 2.1837vw, 2.4rem);

            strong {
                font-size: clamp(3.5rem, 0.0301rem + 4.5181vw, 5rem);
            }
        }

        .label {
            bottom: 3.7rem;
        }
    }

    .back {
        .title {
            font-size: clamp(2.1rem, 0.0181rem + 2.7108vw, 3rem);

            strong {
                font-size: clamp(3.5rem, 0.0301rem + 4.5181vw, 5rem);
            }
        }

        .label {
            bottom: 3.7rem;
            font-size: clamp(0.98rem, 0.0084rem + 1.2651vw, 1.4rem);
        }
    }

    .inner {
        border-width: 0.2rem;
        aspect-ratio: 0.7895;
    }

    .eyebrow {
        padding: 2.4rem 0 1.2rem;
        font-size: clamp(0.977rem, -0.0015rem + 1.2741vw, 1.4rem);
    }

    .textContainer {
        max-width: none;
        justify-content: end;
    }

    .label {
        font-size: clamp(0.98rem, 0.0084rem + 1.2651vw, 1.4rem);
    }

    .image {
        max-height: min(12vw, 18rem);
    }
}

@include large {
    .title {
        max-width: 31rem;
        padding-bottom: 2.4rem;
    }
}
