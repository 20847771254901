@import 'styles/animations';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/mixins';

.root {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: var(--colors-white);
    color: var(--colors-blue);

    &.theme--makers-reserve {
        border-radius: 0;
        color: var(--colors-makersReserveBlack);
    }

    &.theme--faq {
        background-color: transparent;
        color: var(--colors-blue);
    }

    :focus-visible {
        outline-color: var(--colors-blue);
    }

    details {
        width: 100%;

        &:first-child {
            .toggle {
                border-top: none;
            }
        }
    }
}

.label {
    .theme--faq & {
        font-family: $GTWalsheim;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: unset;
        line-height: 1.3;
    }
}

.toggle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    border-top: 1px solid rgba(color('blue'), 0.2);
    text-transform: uppercase;

    .theme--makers-reserve & {
        border-top: 1px solid rgba(color('makersReserveGold'), 0.2);
    }

    .theme--faq & {
        justify-content: space-between;
        text-transform: none;
    }
}

.content {
    color: var(--colors-blue);

    .theme--makers-reserve & {
        border-top: 1px solid rgba(color('makersReserveGold'), 0.2);
        color: var(--colors-makersReserveBlack);
    }

    .theme--faq & {
        padding-right: 4rem;
        padding-bottom: 2rem;
    }
}

.icon {
    position: relative;
    margin-left: 2rem;
    color: var(--colors-blue);
    transition: transform $default $easeInOutCubic;
}

.isOpen {
    .icon {
        transform: rotate(180deg);
        transform-origin: center center;
        transition: transform $default $easeInOutCubic;
    }
}
