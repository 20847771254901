@import 'styles/breakpoints';
@import 'styles/mixins';

.cardIconStat {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: var(--colors-blue);
    color: var(--colors-cream);

    @include rounded-corners($overflow: hidden);
}

.cardLinkWrap {
    display: block;
    width: 100%;
    height: 100%;
}

.inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--inner-padding, 2rem);
    text-align: center;
}

.icon {
    position: relative;
    width: 24rem;
    height: 13rem;
}

.stat {
    text-transform: uppercase;
}

.copy {
    margin: 1rem 0 0;
}

@include medium {
    .cardIconStat {
        --inner-padding: 3.6rem;
    }

    .icon {
        width: 100%;
        height: 13rem;
    }

    .stat {
        margin-top: 2rem;
    }
}

@include large {
    .icon {
        width: 31rem;
    }

    .stat {
        margin-top: 4rem;
    }
}

@include xLarge {
    .cardIconStat {
        --inner-padding: 6.2rem;
        --card-padding: 0 0 131.12% 0;
    }
}
