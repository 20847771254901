@import 'styles/breakpoints';

.blockAccordionFlex {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.themeFAQ {
        background-color: var(--colors-white);
    }
}

.blockAccordion {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) var(--grid-padding-side);
    background-color: var(--colors-cream);
    color: var(--colors-blue);

    .themeFAQ & {
        padding-bottom: var(--block-padding-end);
        background-color: var(--colors-white);
    }

    .flushTop & {
        padding-top: 0;
    }

    .flushBottom & {
        padding-bottom: 0;
    }
}

.cta {
    display: flex;
    justify-content: center;

    .themeFAQ & {
        padding-top: var(--block-padding-end);
    }
}

.textBlock {
    max-width: 102.6rem;
    text-align: center;
}

.bodyCopyBlock {
    max-width: 82.4rem;
    padding: 0 0 var(--block-padding-end);
    text-align: center;
}

.accordionItems {
    overflow: hidden;
    max-width: 82.4rem;
    padding: var(--block-padding-end) 0;
    margin-bottom: 0;
    list-style: none;

    .themeFAQ & {
        padding: var(--block-padding-end) 0 0;
    }

    &::after {
        display: block;
        width: 100%;
        border-top: 0.1rem solid var(--colors-blue);
        margin-bottom: 2.2rem;
        content: '';
    }
}

.groupHeadline {
    margin-bottom: 2rem;
}

.eyebrow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headline {
    margin-bottom: 2.4rem;
}

@include large {
    .headline {
        margin-bottom: 3.6rem;
    }
}
