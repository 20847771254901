@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    --base-dur: 0.4s;

    position: relative;
    color: var(--colors-blue);
}

.heroTransition,
.filtersTransition,
.activeFiltersTransition {
    overflow: hidden;
}

.hero {
    transform: translateY(-2.4rem);
}

.filtersContainer {
    padding-bottom: 2.4rem;
}

.header {
    padding-bottom: 2.4rem;
}

.isSearch {
    .header {
        padding-top: 2.4rem;
    }

    .activeFilters {
        padding: 1.2rem 2.4rem 0;
    }
}

.searchQuery {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: 0 2.4rem;
    margin: 0 auto;
}

.noSearchResults {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    min-height: max(25rem, 50vh);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
    margin: 0 auto;
    gap: 2.4rem;
    text-align: center;
}

.navButton {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    align-items: center;
    padding: 1.8rem 2.4rem;
    margin: 0 auto;
    color: var(--colors-blue);
    cursor: pointer;
}

.navLabel {
    margin-left: 1rem;
    text-transform: uppercase;
}

.activeFilters {
    display: flex;
    max-width: var(--block-max-width-plus-padding);
    flex-flow: row wrap;
    padding: 0 2.4rem;
    margin: 0 auto;
    gap: 0.6rem;
}

.clearAllButton {
    padding-left: 0.6rem;
    opacity: 0;
    text-decoration: underline;
    transition: opacity var(--base-dur);

    &.isVisible {
        opacity: 1;
        transition: opacity var(--base-dur);
    }

    button {
        transition: max-height var(--base-dur), opacity var(--base-dur);
    }

    span {
        font-size: 1.2rem;
    }
}

@include medium {
    .hero {
        transform: none;
    }

    .activeFilters {
        padding: 0 var(--block-padding-side);

        .isSearch & {
            padding: 2.4rem var(--block-padding-side) 0;
        }
    }

    .searchQuery {
        padding: 0 var(--block-padding-side);
    }

    .navButton {
        padding: 3rem var(--block-padding-side);
    }
}

@include large {
    .header {
        padding-bottom: 4.8rem;
    }

    .hero {
        padding-bottom: 2.4rem;
    }

    .filtersContainer {
        padding-bottom: 4.8rem;
    }

    .isSearch {
        .heading {
            .header {
                padding-top: 4.8rem;
                padding-bottom: 4.8rem;
            }
        }
    }

    .noSearchResults {
        min-height: max(20rem, 50vh);
    }
}
