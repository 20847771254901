@use 'sass:math';
@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';
@import 'styles/fonts';

@keyframes animate-in-nav-tem {
    0% {
        opacity: 0.01; // LCP perf hack
        transform: scale(0.6) translateY(-8px);
    }

    100% {
        opacity: 1;
    }
}

@mixin hover-effect {
    .image {
        transform: translateY(-0.4rem);
    }
}

.menu {
    position: absolute;
    z-index: -1;
    width: 100%;
}

.isEntered {
    .root {
        transform: translate3d(0, 0%, 0);
        transition: transform $default $easeInCubic;
    }
}

.isExiting {
    .root {
        transform: translate3d(0, -100%, 0);
        transition: transform 0.3s $easeInCubic;
    }
}

.isExited {
    .root {
        pointer-events: none;
        transform: translate3d(0, -100%, 0);
    }
}

.root {
    min-height: 6rem;

    .menu & {
        background-color: var(--colors-white);
        box-shadow: 0 0.5rem 2rem rgba(188, 185, 151, 20%);
    }
}

.rootInner {
    display: flex;
    width: 100%;
    max-width: 144rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 4vw;
    margin: 0 auto;

    .menu & {
        padding-bottom: 2rem;
    }
}

.item {
    display: flex;
    flex-basis: 33.333%;
    flex-direction: column;
    align-items: center;
    margin: 0 0 1rem;
    animation-delay: calc(var(--animation-order) * #{math.div($fast, 3)});
    animation-duration: $slow;
    animation-fill-mode: both;
    animation-name: animate-in-nav-tem;
    animation-timing-function: $easeOutCubic;
    gap: 1.2rem;
    text-align: center;

    @media (hover: hover) {
        &:hover:not(.itemActive) {
            @include hover-effect;
        }
    }

    &:active:not(.itemActive) {
        @include hover-effect;
    }

    .menu & {
        padding-bottom: 0;
    }

    .flag {
        width: 4rem;
        height: 2rem;
        padding: 0.3rem 0.6rem;
        border-radius: 0.6rem;
        margin-top: 1rem;
        background-color: rgba(#ff9d19, 0.15);
        color: var(--colors-orange);
    }
}

.itemActive {
    cursor: default;

    .label {
        padding-bottom: 0.4rem;
        border-bottom: 0.2rem solid var(--colors-blue);
    }
}

.aspectBox {
    position: relative;
    overflow: hidden;
    width: 7.2rem;
    height: 7.2rem;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center bottom;
    transition: transform $default $easeInOutCubic;
}

.label {
    color: var(--colors-blue);
    text-transform: uppercase;
}

.menuTrigger {
    position: relative;
    z-index: 1;
    display: flex;
    height: 6rem;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    cursor: pointer;
}

.buttonCircle {
    margin-left: 2rem;
    transform: rotate(0deg);
    transition: transform $default $ease;

    .isVisible & {
        transform: rotate(180deg);
    }
}

@include medium {
    .root {
        flex-wrap: nowrap;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
    }

    .rootInner {
        flex-wrap: nowrap;
    }

    .aspectBox {
        width: 12rem;
        height: 12rem;
    }

    .image {
        margin-bottom: 1.6rem;
    }
}
