@import 'styles/animations';

.root {
    position: relative;
    width: 100%;
    height: 100%;

    :global(.animated-icon-utensils-fork),
    :global(.animated-icon-utensils-spoon) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: rotate $slow $bounce, translate $slow $bounce;
    }

    :global(.animated-icon-utensils-fork) {
        margin-left: 0.7rem;
        rotate: 30deg;
        transform-origin: 0 0;
    }

    :global(.animated-icon-utensils-spoon) {
        margin-left: -0.6rem;
        rotate: -31deg;
        transform-origin: 0 0;
    }
}
