@import 'styles/mixins';

.productCategoryPage {
    @include flex-center;

    flex-direction: column;

    .title {
        margin: 20px 0;
    }

    .recipe {
        margin-bottom: 10px;
    }
}
