@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.transition {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.inner {
    --gridColumnAreas: 'a';

    display: grid;
    overflow: hidden;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: 2.4rem;
    margin: 0;
    grid-auto-columns: 1fr;
    grid-gap: 2rem;
    grid-template-areas: var(--gridColumnAreas);
    list-style: none;

    &.truncated {
        padding-bottom: 2.4rem;
    }
}

.listItem {
    box-sizing: border-box;
}

.landscape {
    width: 100%;
    margin: 1.2rem 0 0;
}

.loadButton {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    margin: 0 auto;

    &::after {
        position: absolute;
        top: 50%;
        left: var(--block-padding-side);
        width: calc(100% - var(--block-padding-side) * 2);
        height: 0.1rem;
        background-color: var(--colors-blue);
        content: '';
        opacity: 0.2;
        transform: translateY(-50%);
    }

    .buttonBg {
        z-index: 1;
        display: block;
        width: fit-content;
        height: fit-content;
        background-color: var(--colors-cream);
    }

    button {
        z-index: 2;
        margin: 0 1.2rem;
    }
}

@include medium {
    .inner {
        --gridColumnAreas: 'a a';

        padding: 2.4rem var(--block-padding-side);
    }

    .noResults {
        grid-column: 1 / -1;
    }
}

@include large {
    .inner {
        --gridColumnAreas: 'a a a';

        grid-gap: 2.4rem 1.2rem;
    }

    .doubleWide {
        grid-column: span 2;
    }

    .filterSelect {
        min-width: 19rem;
    }

    .loadButton {
        margin-bottom: var(--block-padding-end);
    }
}
