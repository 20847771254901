@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 36rem;
    height: 5rem;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--colors-white);

    label {
        width: 100%;
    }
}

.input {
    width: 100%;
    height: 100%;
    padding: 1.35rem 1.8rem 1.3rem 1.2rem;
    border: none;

    &:focus-visible {
        outline: none;
    }
}

.submitContainer {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
}

.submitContainer .textButton {
    display: none;
}

.submitContainer .circleButton {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;

    span {
        width: 1rem;
        height: 1rem;
    }
}

.submitButton {
    height: 100%;
    padding: 1.3rem 2.2rem;
    border-radius: 0 !important;

    &:hover {
        background-color: var(--colors-blue);
        color: var(--colors-white);
    }
}

@include large {
    .root {
        max-width: 46.9rem;
    }

    .submitContainer .textButton {
        display: block;
        padding: 1.4rem 2.2rem;
        background-color: var(--colors-blue);
        color: var(--colors-cream);
        text-transform: uppercase;
        transition: all $fast;

        &:hover {
            background-color: var(--colors-blueOcean);
        }
    }

    .submitContainer .circleButton {
        display: none;
    }
}
