@import 'styles/colors';
@import 'styles/mixins.scss';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

.destiniOverlay {
    position: fixed;
    z-index: 10003; // Higher than hotspot overlay
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0rem 1rem;

    background: rgba(black, 0.5);
    opacity: 0;

    &.destiniOverlayIsOpen {
        opacity: 1;
        pointer-events: auto;
        transition: opacity $easeOutQuint $slow, visibility $easeOutQuint $slow;
        visibility: visible;
    }
}

.destiniIframeDiv {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
}

.destiniOverlayInnerWrap {
    position: relative;
    z-index: 1;
    top: 50px;
    bottom: 0;
    overflow: auto;
    width: 100%;

    max-width: 102.4rem;
    height: 80vh;
    margin: 0 auto;
    background: var(--colors-blue);
    border-radius: 1rem;
    filter: drop-shadow(0px 0px 2rem rgba(0, 0, 0, 0.25));
}

.overlayBreadcrumb {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    padding: var(--destiniOverlayClose-padding, 1.8rem 2.5rem);
    background-color: var(--colors-blue);
    color: var(--colors-cream);
    font-family: $GTWalsheimCond;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 120%;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.destiniOverlayCloseIcon {
    position: absolute;
    z-index: 111;
    top: 2rem;
    right: 2rem;
    border: 0;
    background-color: transparent;
    color: var(--colors-cream);

    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.loadingOverlay {
    @include spread;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--colors-white);
    color: white;
    --spinner-color: var(--colors-blue);
}

@media (max-width: 550px) {
    .destiniOverlayInnerWrap {
        padding-top: 3rem;
    }
}

@include touchscreens {
    .destiniOverlayCloseIcon {
        top: 1.5rem;
        right: 1.5rem;
    }
}

@include medium {
    .destiniOverlay {
        display: flex;
        align-items: center;
        padding: 10vh 5rem;
    }

    .destiniOverlayInnerWrap {
        top: 0;
        height: 80vh;
        max-height: 81rem;
        padding-top: 0;
    }
}
