@import 'styles/animations';

.accordionItem {
    display: flex;
    flex-direction: column;
}

.headlineBlock {
    padding-bottom: 1.9rem;
    cursor: pointer;

    .headline {
        text-transform: uppercase;
    }

    .innerBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &::before {
        display: block;
        width: 100%;
        border-top: 1px solid var(--colors-blue);
        margin-bottom: 2.2rem;
        content: '';
    }
}

.copyBlock {
    overflow: hidden;
    height: 0;
    transition: height $default $easeInOutCubic;
}

.iconWrapper {
    position: relative;
    display: flex;
    width: 2.8rem;
    height: 2.8rem;
    justify-content: center;
    color: var(--colors-cream);
    transition: transform $default $easeInOutCubic;

    .icon {
        position: relative;
        opacity: 1;
        transform: rotate(90deg);
    }

    .iconFlipped {
        transform: rotate(270deg);
        transition: transform $default $easeInOutCubic;
    }
}

.copyInnerHeight {
    width: 100%;
}

.copyInner {
    padding: 0 0 2.4rem;
}

// rich text
.accordionItemParagraph {
    margin-bottom: 2rem;
    text-wrap: wrap;

    &:last-child {
        margin-bottom: 0;
    }
}

.accordionItemHeading {
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}
