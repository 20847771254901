@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

@mixin card-hover {
    &:hover {
        .inner {
            transition: background $fast $easeInOut;

            @include ncdTextureOrangeLight;
        }
    }
}

.root {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 1rem;
    appearance: none; // fix safari overflow border-radius bug
    aspect-ratio: 0.5704;
    transform: translateZ(0); // fix safari overflow border-radius bug

    .cta {
        @include block-jams-cta;
    }

    @include card-hover;
}

.inner {
    --padding: 0 1rem 2rem;

    position: relative;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--padding);
    border: 0.2rem solid var(--colors-blue);
    border-radius: 1rem;
    transform: translateZ(0); // fix safari overflow border-radius bug
    transition: transform $default $easeOutCubic, background $default $easeInOut;

    @include flex-center;
    @include ncdTextureOrangeMedium;

    picture {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
    }
}

.textContainer {
    display: flex;
    max-width: 14.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.title {
    position: relative;
    max-width: 22rem;
    padding-bottom: 1rem;
    text-align: center;
}

.image {
    position: relative;
    z-index: 10;
    width: 37vw;
    aspect-ratio: 1.2;
    object-fit: contain;
}

@include medium {
    .root {
        border-radius: 2rem;
        aspect-ratio: 0.7221;
    }

    .inner {
        --padding: 0 3.6rem 5.6rem;

        border-width: 0.4rem;
        border-radius: 2rem;

        picture {
            margin: 0;
        }
    }

    .image {
        padding: 0 0.7rem;
    }

    .textContainer {
        max-width: none;
        justify-content: end;
    }

    .cta {
        width: fit-content;
    }
}

@include large {
    .inner {
        --padding: 0 3rem 4rem;
    }

    .title {
        max-width: 31rem;
        padding-bottom: 2.4rem;
    }

    .image {
        width: min(var(--block-max-width) * 0.25, 25vw);
    }
}
