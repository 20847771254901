@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

@mixin campaign-card-hover {
    .imageContainer {
        transform: scale(0.9);
    }

    .image {
        transform: scale(1.2);
    }

    .headline,
    .description {
        transform: translateY(-1.5rem);
    }
}

.campaign {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--campaign-padding, 5.6rem 4.7rem);
    text-align: center;

    @include rounded-corners;

    @media (hover: hover) {
        &:hover {
            @include campaign-card-hover;
        }
    }

    &:active {
        @include campaign-card-hover;
    }
}

.imageContainer {
    --image-dimension: 12.5rem;

    overflow: hidden;
    width: var(--image-dimension);
    height: var(--image-dimension);
    border-radius: 50%;

    // fixes border-radius bug in iOS
    mask-image: radial-gradient(white, black);

    // hover effect
    transform: scale(1);
    transition: transform $easeOutCubic $slow;
}

.image {
    height: 100%;

    // hover effect
    transform: scale(1);
    transition: transform $easeOutCubic $slow;
}

.headline {
    margin: var(--headline-margin, 3.4rem 0 2rem);
    text-transform: uppercase;

    // hover effect
    transform: translateY(0);
    transition: transform $easeOutCubic $default;
}

.description {
    max-width: var(--description-max-width, 24.1rem);
    margin: 0 0 2rem;

    // hover effect
    transform: translateY(0);
    transition: transform $easeOutCubic $default;
}

.iconWrapper {
    position: relative;
    width: 2.7rem;
    height: 2.7rem;
}

@include large {
    .campaign {
        min-height: 51rem;

        --campaign-padding: 4.8rem 2.4rem;
    }
}

@include xLarge {
    .description {
        --description-max-width: 34.9rem;
    }
}

@include xxLarge {
    .campaign {
        --campaign-padding: 9.6rem 5.6rem;
    }

    .imageContainer {
        --image-dimension: 21rem;
    }
}

@include xxxLarge {
    .campaign {
        --campaign-padding: 13.6rem 5.6rem;
    }
}
