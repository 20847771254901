/* stylelint-disable max-nesting-depth */
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';
@import 'components/ui/AnimatedIcon/AnimatedIcon.module';

.root {
    display: block;
    height: 100%;
    padding: 4.8rem 2.4rem;
    border-radius: 2rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    text-align: center;
    transition: translate $slow $bounce;

    @media (hover: hover) {
        &:hover {
            translate: 0 -3rem;

            .utensils {
                @include animated-icon-utensils-fork;
                @include animated-icon-utensils-spoon;
            }

            .packaging {
                @include animated-icon-packaging-earth;
            }

            .farmers {
                @include animated-icon-farmers-arrow;
            }
        }
    }
}

.inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    margin-bottom: 2.4rem;
    background-color: var(--colors-cream);
}

.headline {
    max-width: 30rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.copy {
    margin-bottom: 2rem;
}

@include xxLarge {
    .root {
        aspect-ratio: 408/454;
    }
}
